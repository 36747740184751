import PropTypes from 'prop-types'
import React from 'react'

const Footer = ({ siteTitle }) => (
  <footer className="pt-8 max-w-2xl border-t border-grey-light">
    © {new Date().getFullYear()}, {siteTitle}
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: `Colin Genlloud`,
}

export default Footer
