import classNames from 'classnames'
import { graphql, Link, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

class Header extends Component {
  state = {
    isActive: false,
  }

  toggleDropdown = () =>
    this.setState(state => ({
      isActive: !state.isActive,
    }))

  render() {
    const menuClass = classNames({
      'nav-wrapper': true,
      'lg:block': true,
      'is-active': this.state.isActive,
    })

    const btnClass = classNames({
      'nav-toggle': true,
      'lg:hidden': true,
      'is-active': this.state.isActive,
    })

    return (
      <div className="header fixed pin-t pin-x z-10 lg:relative flex flex-col justify-between flex-no-shrink lg:p-8 lg:pt-32 lg:min-w-1/4 bg-black text-white">
        <header className="relative z-10 flex flex-col">
          <div className="lg:block flex justify-between items-center">
            <p className="lg:mb-2 pl-4 lg:pl-0 text-xl lg:text-5xl font-bold lg:leading-normal">
              <Link className="text-white no-underline" to="/">
                {this.props.siteTitle}
              </Link>
            </p>
            <p className="hidden lg:block mb-0 text-xl leading-normal">
              Suffolk based artist and painter.
            </p>
            <button
              className={btnClass}
              onClick={this.toggleDropdown}
              aria-label="Toggle Navigation Menu"
            >
              <span className="nav-toggle-text">Toogle Navigation Menu</span>
            </button>
          </div>
          <nav className={menuClass}>
            <ul className="nav lg:my-8">
              {this.props.menuLinks.map(link => (
                <li className="nav-item" key={link.name}>
                  <Link
                    className="nav-link"
                    partiallyActive={true}
                    to={link.link}
                    activeClassName={link.link === '/' && !this.props.home ? null : 'is-active'}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </header>
        <StaticQuery
          query={graphql`
            query {
              placeholderImage: file(relativePath: { eq: "hero/hero-bg-1.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 410) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          `}
          render={data => (
            <Img
              fluid={data.placeholderImage.childImageSharp.fluid}
              className="absolute pin hidden lg:block w-full h-full object-cover object-center"
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
              }}
              critical={true}
            />
          )}
        />
      </div>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Colin Genlloud`,
}

export default Header
