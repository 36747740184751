import { Location } from '@reach/router'
import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Footer from './footer'
import Header from './header'

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => (
      <div className="lg:flex lg:h-screen lg:overflow-hidden pt-14 lg:pt-0">
        <Location>
          {({ location }) => {
            return (
              <Header
                home={location.pathname === '/'}
                siteTitle={data.site.siteMetadata.title}
                menuLinks={data.site.siteMetadata.menuLinks}
              />
            )
          }}
        </Location>
        <div className="flex flex-col min-h-screen flex-grow lg:overflow-auto">
          <main className="flex-grow p-4 lg:p-8 max-w-3xl">{children}</main>
          <div className="px-4 pb-4 lg:px-8 lg:pb-8">
            <Footer />
          </div>
        </div>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.bool,
}

Layout.defaultProps = {
  home: false,
}

export default Layout
